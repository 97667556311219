
import Vue from 'vue';
import AvatarImage from '@/components/AvatarImage.vue';

interface Data {
  file: File | null;
  imageUrl: string | null;
}

interface Props {
  label: string;
  icon: string;
  color: string;
  disabled: boolean;
}

interface Methods {
  upload(): void;

  clear(): void;
}

export type FileInputImageType = Vue & Data & Props & Methods;

export default Vue.extend<Data, Methods, Record<string, never>, Props>({
  name: 'FileInputImage',
  components: { AvatarImage },
  props: {
    label: { type: String, required: true },
    icon: { type: String, default: '' },
    color: { type: String, default: 'white' },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    file: null,
    imageUrl: null
  }),
  methods: {
    upload() {
      if (this.file) {
        this.imageUrl = URL.createObjectURL(this.file);
        this.$emit('image-selected', this.file);
      }
    },
    clear() {
      this.file = null;
      this.imageUrl = null;
      this.$emit('image-selected', this.file);
    }
  }
});
