
import AvatarCard from '@/components/AvatarCard.vue';
import PlaceHolderNewFeature from '@/components/PlaceHolderNewFeature.vue';
import Vue from 'vue';

interface Props {
  title: string;
  subTitle: string;
  avatar: string;
  icon: string;
  color: string;
  sponsor: boolean;
  loading: boolean;
}

export default Vue.extend<
  Record<string, never>,
  Record<string, never>,
  Record<string, never>,
  Props
>({
  name: 'HomeCard',
  components: { AvatarCard, PlaceHolderNewFeature },
  props: {
    title: { type: String, required: true },
    subTitle: { type: String, required: true },
    avatar: { type: String },
    icon: { type: String },
    color: { type: String, required: true },
    loading: {
      type: Boolean,
      default: true
    },
    sponsor: {
      type: Boolean,
      default: false
    }
  }
});
