
import Vue from 'vue';
import { Horse } from '@/store/modules/horse';
import { Notification } from '@/helpers/notifications';
import NotificationBadge from '@/components/NotificationBadge.vue';

interface Props {
  horseId: string;
}

interface Computed {
  horse: Horse;
}

interface Data {
  food: Notification[] | null;
  health: Notification[] | null;
  horseshoe: Notification[] | null;
  sport: Notification[] | null;
}

export default Vue.extend<Data, Record<string, never>, Computed, Props>({
  name: 'ModuleIconCard',
  components: { NotificationBadge },
  props: {
    horseId: { type: String, default: null }
  },
  data: () => ({
    food: null,
    health: null,
    horseshoe: null,
    sport: null
  }),
  computed: {
    horse() {
      return this.$store.getters['HorseModule/getById'](this.horseId);
    }
  },
  watch: {
    'horse.notifications': {
      immediate: true,
      deep: true,
      handler(oldVal, newVal) {
        if (newVal) {
          this.food = newVal.food;
          this.health = newVal.health;
          this.horseshoe = newVal.horseshoe;
          this.sport = newVal.sport;
        } else if (oldVal) {
          this.food = oldVal.food;
          this.health = oldVal.health;
          this.horseshoe = oldVal.horseshoe;
          this.sport = oldVal.sport;
        }
      }
    }
  }
});
