
import { mdiPlus } from '@mdi/js';
import Vue from 'vue';

interface Props {
  cardHeight: number;
  cardText: string;
  disabled: string;
}

interface Computed {
  mdiPlus: string;
}

export default Vue.extend<
  Record<string, never>,
  Record<string, never>,
  Computed,
  Props
>({
  name: 'NewCard',
  props: {
    cardHeight: { type: Number, default: 200 },
    cardText: { type: String, required: true },
    disabled: { type: String, default: null }
  },
  computed: {
    mdiPlus() {
      return mdiPlus;
    }
  }
});
