
import Vue from 'vue';
import {
  deleteNotification,
  getNotificationView,
  markAsReadNotification,
  Notification,
  NotificationView
} from '@/helpers/notifications';
import { mdiCheckAll, mdiClose } from '@mdi/js';

interface Props {
  notifications: Notification[];
  icon: string;
}

interface Methods {
  markAsRead(notification: Notification): void;

  remove(notification: Notification): void;
}

interface Computed {
  notificationCounter: number;
  notificationList: NotificationView[];
  mdiCheckAll: string;
  mdiCheck: string;
  mdiClose: string;
}

export default Vue.extend<Record<string, never>, Methods, Computed, Props>({
  name: 'NotificationBadge',
  props: {
    notifications: {
      type: Array
    },
    icon: {
      type: String,
      default: null
    }
  },
  methods: {
    markAsRead(notification) {
      markAsReadNotification(notification);
    },
    remove(notification) {
      deleteNotification(notification);
    }
  },
  computed: {
    mdiCheck() {
      return mdiCheckAll;
    },
    mdiCheckAll() {
      return mdiCheckAll;
    },
    mdiClose() {
      return mdiClose;
    },
    notificationCounter() {
      if (this.notificationList && this.notificationList.length) {
        return this.notificationList.filter(
          (item: NotificationView) => !item.original.read
        ).length;
      } else {
        return 0;
      }
    },
    notificationList() {
      const notificationsView: NotificationView[] = [];
      if (this.notifications) {
        this.notifications.forEach((item) => {
          if (
            this.$dayjs(item.end).diff(
              this.$dayjs(new Date()),
              item.notificationPeriod.start.unit
            ) <= item.notificationPeriod.start.time
          ) {
            const notificationView = getNotificationView(item);
            if (notificationView) {
              notificationsView.push(notificationView);
            }
          } //else notification should not be showed
        });
        return notificationsView.sort((a, b) => {
          const now = new Date().getTime();
          const timeB = new Date(b.original.end).getTime();
          const timeA = new Date(a.original.end).getTime();
          if (timeB - now >= 0) {
            return timeB - timeA + now;
          } else {
            return timeB - timeA;
          }
        });
      } else {
        return [];
      }
    }
  }
});
