
import Vue from 'vue';
import ImageKit from 'imagekit-javascript';

interface Methods {
  forward(): void;
}

interface Props {
  avatar: string | ImageKit.UploadResponse;
  contain: boolean;
  icon: string;
  color: string;
  badgeSize: string;
  link: string;
  sheetSpacing: string;
}

interface Computed {
  size: {
    icon: number;
    iconClass: string;
    avatar: number;
    avatarClass: string;
    sheet: number;
  };
}

export default Vue.extend<Record<string, never>, Methods, Computed, Props>({
  name: 'AvatarImage',

  methods: {
    forward() {
      if (this.$props.link !== null) {
        this.$router.push(this.$props.link);
      }
    }
  },
  props: {
    avatar: {
      type: [String, Object],
      default: 'small'
    },
    contain: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: 'white'
    },
    badgeSize: {
      type: String,
      default: 'small'
    },
    link: {
      type: String,
      default: null
    },
    sheetSpacing: {
      type: String,
      default: ''
    }
  },
  computed: {
    size() {
      if (this.$props.badgeSize === 'small' || this.$props.avatar === 'small') {
        return {
          icon: 30,
          iconClass: 'small--offset',
          avatar: 56,
          avatarClass: 'small--avatar--offset',
          sheet: 60
        };
      }
      if (this.$vuetify.breakpoint.xs) {
        return {
          icon: 84,
          iconClass: 'ms-1',
          avatar: 82,
          avatarClass: 'mt-1 ms-1',
          sheet: 90
        };
      }
      return {
        icon: 110,
        iconClass: 'ms-1',
        avatar: 110,
        avatarClass: 'mt-1 ms-1',
        sheet: 120
      };
    }
  }
});
