
import Vue from 'vue';

interface Props {
  hintTxt: string;
  showOverlay: boolean;
}

export default Vue.extend<
  Record<string, never>,
  Record<string, never>,
  Record<string, never>,
  Props
>({
  name: 'PlaceHolderNewFeature',
  props: {
    hintTxt: {
      type: String,
      default: 'placeholder-new-feature.upcoming-feature'
    },
    showOverlay: {
      type: Boolean,
      default: true
    }
  }
});
