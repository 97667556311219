
import Vue from 'vue';
import { mask } from 'vue-the-mask';
import { Validation, validationMixin } from 'vuelidate';
import { or, required, requiredIf } from 'vuelidate/lib/validators';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;
import { Address, Stable } from '@/store/modules/stable';
import { showError } from '@/helpers/utils';
import FormDialog from '@/components/FormDialog.vue';
import vuelidateMixin from '@/helpers/vuelidateMixin';
import { cellphone, phone } from '@/helpers/validations';
import { ValidationGroups } from 'vue/types/vue';

interface Props {
  dialog: boolean;
}

interface Data {
  stable: Stable;
  displayPhone: string;
  creating: boolean;
}

interface Methods {
  create(): void;
  cancel(): void;
  touchAddressFields(): void;
}

interface Computed {
  phoneError: TranslateResult[];
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'StableCreate',
  mixins: [validationMixin, vuelidateMixin],
  components: { FormDialog },
  directives: { mask },
  validations: {
    stable: {
      name: { required },
      address: {
        line1: {
          required: requiredIf(function (vm) {
            return !!(
              vm.city ||
              vm.postCode ||
              vm.state ||
              vm.line2 ||
              vm.country
            );
          })
        },
        state: {
          required: requiredIf(function (vm) {
            return !!(
              vm.city ||
              vm.postCode ||
              vm.line1 ||
              vm.line2 ||
              vm.country
            );
          })
        },
        city: {
          required: requiredIf(function (vm) {
            return !!(
              vm.state ||
              vm.postCode ||
              vm.line1 ||
              vm.line2 ||
              vm.country
            );
          })
        },
        country: {
          required: requiredIf(function (vm) {
            return !!(
              vm.state ||
              vm.postCode ||
              vm.line1 ||
              vm.line2 ||
              vm.city
            );
          })
        },
        postCode: {
          required: requiredIf(function (vm) {
            return !!(
              vm.state ||
              vm.city ||
              vm.line1 ||
              vm.line2 ||
              vm.country
            );
          })
        }
      }
    },
    displayPhone: { validFormat: or(phone, cellphone) }
  },
  data: () => ({
    creating: false,
    stable: {
      id: '',
      name: '',
      horses: [],
      address: {
        line1: '',
        line2: '',
        postCode: '',
        city: '',
        state: '',
        country: ''
      },
      phone: ''
    },
    displayPhone: ''
  }),
  methods: {
    create() {
      this.creating = true;
      const stableNew = Object.assign({}, this.stable);
      if (
        !stableNew.address?.state &&
        !stableNew.address?.city &&
        !stableNew.address?.postCode &&
        !stableNew.address?.line1 &&
        !stableNew.address?.line2 &&
        !stableNew.address?.country
      ) {
        delete stableNew.address;
      }
      if (this.displayPhone) {
        stableNew.phone = '+55' + this.displayPhone.replace(/[^.\d]/g, '');
      }
      if (!this.$v.stable.$anyError && this.$v.stable.$anyDirty) {
        this.$store
          .dispatch('StableModule/createStable', stableNew)
          .then(() => {
            this.creating = false;
            this.stable = {} as Stable;
            this.stable.address = {} as Address;
            this.displayPhone = '';
            this.$v.stable.$reset();
            this.$emit('stable-created');
          })
          .catch((error) => {
            showError(error);
            this.creating = false;
          });
      } else {
        this.creating = false;
        this.$v.stable.$touch();
      }
    },
    touchAddressFields() {
      this.$v.stable.address?.$touch();
    },
    cancel() {
      this.stable = {
        id: '',
        name: '',
        horses: [],
        address: {
          line1: '',
          line2: '',
          postCode: '',
          city: '',
          state: '',
          country: ''
        },
        phone: ''
      };
      this.$v.$reset();
      this.$emit('cancel');
    }
  },
  computed: {
    phoneError() {
      const errors: TranslateResult[] = [];
      const field = this.$v.displayPhone as ValidationGroups & Validation;
      if (!field.$dirty) return [];
      !field.validFormat &&
        errors.push(this.$t('stableCreateFlow.errorMessages.phoneIsInvalid'));
      return errors;
    }
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    }
  }
});
