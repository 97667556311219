import { MODULE_TYPES } from '@/store/constants';
import { i18n } from '@/i18n';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;
import { mdiCommentQuestionOutline } from '@mdi/js';

export interface Highlight {
  title: TranslateResult;
  subTitle: TranslateResult;
  color: string;
  icon?: string;
  avatar?: string;
  sponsor?: boolean;
}

//Health
export function vaccine(horses: number): Highlight {
  return {
    title: i18n.t('highlights.vaccine.title'),
    subTitle: i18n.tc('highlights.vaccine.subTitle', horses, { n: horses }),
    icon: MODULE_TYPES.HEALTH.icon,
    color: MODULE_TYPES.HEALTH.color
  };
}

//sport
export function competitionNextWeek(
  competitionName: string,
  horse: string
): Highlight {
  return {
    title: i18n.t('highlights.competitionNextWeek.title'),
    subTitle: i18n.t('highlights.competitionNextWeek.subTitle', {
      competitionName: competitionName,
      horse: horse
    }),
    icon: MODULE_TYPES.SPORT.icon,
    color: MODULE_TYPES.SPORT.color
  };
}

//breeding
export function foalBirthForecast(
  horseName: string,
  days: number,
  moonType: TranslateResult
): Highlight {
  return {
    title: i18n.t('highlights.foalBirthForecast.title'),
    subTitle: i18n.t('highlights.foalBirthForecast.subTitle', {
      horseName: horseName,
      days: days,
      moonType: moonType
    }),
    icon: MODULE_TYPES.REPRODUCTION.icon,
    color: MODULE_TYPES.REPRODUCTION.color
  };
}

//food
export function rationEnding(rationName: string, horses: number): Highlight {
  return {
    title: i18n.t('highlights.rationEnding.title'),
    subTitle: i18n.t('highlights.rationEnding.subTitle', {
      rationName: rationName,
      horses: horses
    }),
    icon: MODULE_TYPES.FOOD.icon,
    color: MODULE_TYPES.FOOD.color
  };
}

//tips
export function tipHorse(position: number): Highlight {
  return {
    title: i18n.t(`highlights.tips.horse[${position}].title`),
    subTitle: i18n.t(`highlights.tips.horse[${position}].subTitle`),
    icon: mdiCommentQuestionOutline,
    color: 'teal'
  };
}

//sponsor
export function sponsor(brand: string, position: number): Highlight {
  return {
    title: i18n.t(`highlights.sponsor.${brand}[${position}].title`),
    subTitle: i18n.t(`highlights.sponsor.${brand}[${position}].subTitle`),
    avatar:
      'https://www.racoesvale.com.br/wp-content/uploads/2019/05/logo-footer.png',
    color: 'white',
    sponsor: true
  };
}
