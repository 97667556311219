
import ModuleIconCard from '@/components/modules/ModuleIconCard.vue';
import AvatarCard from '@/components/AvatarCard.vue';
import Vue from 'vue';
import { Horse } from '@/store/modules/horse';
import { mapState } from 'vuex';
import { shortStringDots } from '@/helpers/utils';
import { Space } from '@/store/modules/space';
import { mdiHorseVariant } from '@mdi/js';

interface Props {
  horseId: string;
}

interface Computed {
  currentSpace: Space;
  horse: Horse | undefined;
  mdiHorseVariant: string;
}

interface Methods {
  horseName(n: number): string;
}

export default Vue.extend<Record<string, unknown>, Methods, Computed, Props>({
  name: 'HorseCard',
  components: { AvatarCard, ModuleIconCard },
  props: {
    horseId: {
      type: String,
      required: true
    }
  },
  methods: {
    horseName(n: number) {
      if (!this.horse) return '';
      return shortStringDots(this.horse.name, n);
    }
  },
  computed: {
    ...mapState('UserModule', ['currentSpace']),
    mdiHorseVariant() {
      return mdiHorseVariant;
    },
    horse() {
      return this.$store.getters['HorseModule/getById'](this.horseId);
    }
  }
});
