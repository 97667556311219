
import Vue from 'vue';
import AvatarImage from '@/components/AvatarImage.vue';
import ImageKit from 'imagekit-javascript';

interface Methods {
  forward(): void;
}

interface Props {
  avatar: string | ImageKit.UploadResponse;
  contain: boolean;
  icon: string;
  color: string;
  height: number;
  badgeSize: string;
  loading: boolean;
  link: string;
}

interface Computed {
  size: 'small' | 'big';
}

export default Vue.extend<Record<string, never>, Methods, Computed, Props>({
  name: 'AvatarCard',
  components: { AvatarImage },
  methods: {
    forward() {
      if (this.link !== null) {
        this.$router.push(this.link);
      }
    }
  },
  props: {
    avatar: {
      type: [String, Object]
    },
    contain: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String
    },
    color: {
      type: String,
      default: 'white'
    },
    height: {
      type: Number,
      default: 150
    },
    badgeSize: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    link: {
      type: String,
      required: false
    }
  },
  computed: {
    size() {
      return this.$props.badgeSize
        ? this.$props.badgeSize
        : this.$props.avatar
        ? 'big'
        : 'small';
    }
  }
});
