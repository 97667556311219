import { helpers } from 'vuelidate/lib/validators';

export const alphaAndSpaces = helpers.regex('alphaAndSpaces', /^[a-z ]*$/i);
export const mustHaveAlphaCaps = helpers.regex(
  'mustHaveAlphaCaps',
  /(?=.*[A-Z])/
);
export const mustHaveAlphaNonCaps = helpers.regex(
  'mustHaveAlphaNonCaps',
  /(?=.*[a-z])/
);

export const numberSpace = helpers.regex('numberSpace', /^[\D\s]*$/);
export const numberDecimal = helpers.regex(
  'numberDecimal',
  /^(\d,\d|\d\.\d|\d)*$/
);
export const cardFormat = helpers.regex(
  'cardFormat',
  /\d{4}\s\d{4}\s\d{4}\s\d{4}/
);
export const amexFormat = helpers.regex('amexFormat', /\d{4}\s\d{6}\s\d{5}\s/);
export const cardDate = helpers.regex('cardDate', /(1[0-2]|0[1-9])\/\d{4}/);
export const dateBR = helpers.regex(
  'dateBR',
  /(0[1-9]|[1-2][0-9]|3[0-1])\/(1[0-2]|0[1-9])\/\d{4}/
);
export const dateEN = helpers.regex(
  'dateEN',
  /(1[0-2]|0[1-9])\/([0[1-9]|[1-2][0-9]|3[0-1])\/\d{4}/
);
export const cpf = helpers.regex('cpf', /\d{3}\.\d{3}\.\d{3}-\d{2}/);
export const cnpj = helpers.regex('cnpj', /\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}/);
export const mustHaveNumber = helpers.regex('mustHaveNumber', /(?=.*\d)/);
export const mustHaveSpecialChar = helpers.regex('alpha', /(?=.*[\W])/);
export const PASSWORD_MIN_LENGTH = 6;
export const phone = helpers.regex('phone', /\(\d{2}\) \d{4}-\d{4}/);
export const cellphone = helpers.regex('cellphone', /\(\d{2}\) \d{5}-\d{4}/);
