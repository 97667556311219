
import HorseCard from "@/components/horse/HorseCard.vue";
import HomeCard from "@/components/HomeCard.vue";
import StableCard from "@/components/stable/StableCard.vue";
import StableCreate from "@/components/stable/StableCreate.vue";
import { Stable } from "@/store/modules/stable";
import HorseCreate from "@/components/horse/HorseCreate.vue";
import * as Highlight from "@/store/highlights";
import Vue from "vue";
import { mapState } from "vuex";
import NewCard from "@/components/NewCard.vue";
import { Space } from "@/store/modules/space";
import { TYPE } from "@/store/modules/notification";
import { Horse } from "@/store/modules/horse";
import { mdiViewModule, mdiViewSequential } from "@mdi/js";

interface Data {
  highlights: Highlight.Highlight[];
  loading: boolean;
  hideUpcomingFeatures: boolean;
  newStableDialog: boolean;
  viewAsStables: boolean;
  newHorseDialog: boolean;
}

interface Methods {
  stableCreated(): void;

  horseCreated(): void;
}

interface Computed {
  stables: Stable[];
  horses: Horse[];
  currentSpace: Space;
  stableLimit: boolean;
  mdiViewSequential: string;
  mdiViewModule: string;
}

export default Vue.extend<Data, Methods, Computed, Record<string, never>>({
  name: 'Home',
  data: () => ({
    newStableDialog: false,
    loading: false,
    hideUpcomingFeatures: true,
    viewAsStables: false,
    newHorseDialog: false,
    highlights: []
  }),
  async created() {
    if (
      this.stables.length > 0 &&
      this.stables[0].horses &&
      this.stables[0].horses.length > 0
    ) {
      this.highlights = [
        Highlight.vaccine(1),
        Highlight.competitionNextWeek('Olimpíadas 2021', ''),
        Highlight.foalBirthForecast(
          'Colly',
          15,
          this.$t('moonType.waningCrescent')
        ),
        Highlight.rationEnding('Total', 3)
      ];
    } else {
      this.highlights = [Highlight.tipHorse(0), Highlight.sponsor('vale', 0)];
    }
  },
  components: {
    HomeCard,
    HorseCard,
    HorseCreate,
    NewCard,
    StableCard,
    StableCreate
  },
  methods: {
    stableCreated() {
      this.$store.commit('NotificationModule/add', {
        msg: this.$t('home.stableCreate.success.message'),
        type: TYPE.SUCCESS
      });
      this.newStableDialog = false;
    },
    horseCreated() {
      this.$store.commit('NotificationModule/add', {
        msg: this.$t('stable.create-horse.success.message'),
        type: TYPE.SUCCESS
      });
      this.newHorseDialog = false;
    }
  },
  computed: {
    ...mapState('StableModule', ['stables']),
    ...mapState('UserModule', ['currentSpace']),
    horses() {
      const horses: Horse[] = [];
      for (const stable of this.stables) {
        for (const id of stable.horses) {
          const horse = this.$store.getters['HorseModule/getById'](id);
          if (horse) horses.push(horse);
        }
      }
      return horses;
    },
    stableLimit() {
      const stableCount = this.stables.length;
      const limit =
        this.currentSpace && this.currentSpace.plan
          ? this.currentSpace.plan.stable
          : 1000;
      return stableCount >= limit;
    },
    mdiViewModule() {
      return mdiViewModule;
    },
    mdiViewSequential() {
      return mdiViewSequential;
    }
  }
});
