
import Vue from 'vue';
import FormCard from '@/components/FormCard.vue';

interface Props {
  formValid: boolean;
  dialog: boolean;
  creating: boolean;
  title: string;
  width: number;
  height: number;
  buttonSaveLabel: string;
}

export default Vue.extend<
  Record<never, never>,
  Record<never, never>,
  Record<never, never>,
  Props
>({
  name: 'FormDialog',
  components: { FormCard },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    creating: {
      type: Boolean,
      required: true
    },
    formValid: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 900
    },
    height: {
      type: Number,
      default: 800
    },
    buttonSaveLabel: {
      type: String,
      default: ''
    }
  }
});
