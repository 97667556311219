
import Vue, { PropType } from "vue";
import { Horse } from "@/store/modules/horse";
import { User } from "@/store/modules/user";
import { mapState } from "vuex";
import { required, requiredUnless } from "vuelidate/lib/validators";
import FileInputImage, { FileInputImageType } from "@/components/FileInputImage.vue";
import { ERROR_LIST } from "@/helpers/errors";
import { showError } from "@/helpers/utils";
import { validationMixin } from "vuelidate";
import { LocalizedSelectItem } from "@/helpers/types";
import { horseRaces } from "@/store/constants";
import FormDialog from "@/components/FormDialog.vue";
import vuelidateMixin from "@/helpers/vuelidateMixin";
import { mdiHorseVariant, mdiPlus } from "@mdi/js";
import { Stable } from "@/store/modules/stable";
import { TYPE } from "@/store/modules/notification";

interface Props {
  stableId: string;
  spaceId: string;
  dialog: boolean;
  stables: Stable[];
}

interface Data {
  horse: Horse;
  horseImg: File | null;
  creating: boolean;
  stable: Stable;
  selectedStable: Stable | undefined;
  search: string;
}

interface Computed {
  horseRaces: readonly string[];
  user: User;
  localizedCategoryList: LocalizedSelectItem[];
  imageUrl: string;
  mdiHorseVariant: string;
  mdiPlus: string;
}

interface Methods {
  create(): void;
  createStable(): void;
  cancel(): void;
  setImage(file: File): void;
  newHorse(): void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'HorseCreate',
  components: { FileInputImage, FormDialog },
  mixins: [validationMixin, vuelidateMixin],
  validations: {
    horse: {
      name: { required },
      sex: { required },
      category: { required },
      race: { required }
    },
    selectedStable: {
      required: requiredUnless(function (vm) {
        return !!vm.stableId;
      })
    }
  },
  data: () => ({
    search: '',
    creating: false,
    horse: {
      id: '',
      name: '',
      sex: '',
      category: '',
      pelage: '',
      race: '',
      owner: '',
      notifications: null
    },
    horseImg: null,
    stable: {
      id: '',
      name: '',
      horses: []
    },
    selectedStable: undefined
  }),
  methods: {
    createStable() {
      const stableNew = Object.assign({}, this.stable);
      stableNew.name = this.search;
      this.$store
        .dispatch('StableModule/createStable', stableNew)
        .then((stable) => {
          this.selectedStable = stable;
          this.$store.commit('NotificationModule/add', {
            msg: this.$t('home.stableCreate.success.message'),
            type: TYPE.SUCCESS
          });
        })
        .catch((error) => {
          showError(error);
          this.creating = false;
        });
    },
    newHorse() {
      this.horse = {
        id: '',
        name: '',
        sex: '',
        category: '',
        pelage: '',
        race: '',
        owner: '',
        notifications: null
      };
    },
    create() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.creating = true;
        this.horse.owner = this.user.name;
        const horseNew = Object.assign({}, this.horse);
        const stableId = this.selectedStable?.id
          ? this.selectedStable.id
          : this.stableId;
        this.$store
          .dispatch('HorseModule/createHorse', {
            horse: horseNew,
            avatar: this.horseImg,
            stableId: stableId,
            spaceId: this.spaceId,
            notifications: null
          })
          .then(() => {
            this.creating = false;
            this.$emit('horse-created');
            this.newHorse();
            this.selectedStable = undefined;
            this.horseImg = null;
            (this.$refs.fileImage as FileInputImageType).clear();
            this.$v.$reset();
          })
          .catch((error) => {
            showError(error);
            this.creating = false;
            if (error.code === ERROR_LIST.HORSE_CREATE_IMAGE_FAIL.code) {
              this.cancel();
            }
          });
      }
    },
    cancel() {
      this.newHorse();
      this.$v.$reset();
      this.$emit('cancel');
    },
    setImage(file) {
      this.horseImg = file;
    }
  },
  computed: {
    ...mapState('UserModule', ['user']),
    mdiHorseVariant() {
      return mdiHorseVariant;
    },
    horseRaces() {
      return horseRaces;
    },
    mdiPlus() {
      return mdiPlus;
    },
    localizedCategoryList() {
      if (this.horse.sex === 'horse-edit-profile-card.fields.female') {
        return [
          {
            label: this.$t('horse-edit-profile-card.category.matrix'),
            value: 'horse-edit-profile-card.category.matrix'
          },
          {
            label: this.$t('horse-edit-profile-card.category.sport'),
            value: 'horse-edit-profile-card.category.sport'
          },
          {
            label: this.$t('horse-edit-profile-card.category.receptor'),
            value: 'horse-edit-profile-card.category.receptor'
          }
        ];
      }
      return [
        {
          label: this.$t('horse-edit-profile-card.category.stallion'),
          value: 'horse-edit-profile-card.category.stallion'
        },
        {
          label: this.$t('horse-edit-profile-card.category.castrated'),
          value: 'horse-edit-profile-card.category.castrated'
        }
      ];
    },
    imageUrl() {
      if (this.horseImg) {
        return URL.createObjectURL(this.horseImg);
      } else {
        return '';
      }
    }
  },
  props: {
    stableId: {
      type: String,
      required: false
    },
    stables: {
      type: Array as PropType<Stable[]>,
      required: false
    },
    spaceId: {
      type: String,
      required: true
    },
    dialog: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    'horse.sex': {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.horse.category = '';
        }
      },
      deep: true
    }
  }
});
