
import { mdiClose } from '@mdi/js';
import Vue from 'vue';

interface Data {
  valid: boolean;
}

interface Props {
  formValid: boolean;
  creating: boolean;
  title: string;
  buttonSaveLabel: string;
  height: number;
}

interface Computed {
  mdiClose: string;
}

export default Vue.extend<Data, Record<never, never>, Computed, Props>({
  name: 'FormCard',
  data: () => ({
    valid: false
  }),
  computed: {
    mdiClose() {
      return mdiClose;
    }
  },
  props: {
    creating: {
      type: Boolean,
      required: true
    },
    formValid: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    buttonSaveLabel: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: null
    }
  }
});
