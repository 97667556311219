
import ModuleIconCard from '@/components/modules/ModuleIconCard.vue';
import AvatarImageKit from '@/components/AvatarImageKit.vue';
import Vue from 'vue';
import { Stable } from '@/store/modules/stable';
import { mapState } from 'vuex';
import { Space } from '@/store/modules/space';
import { shortStringDots } from '@/helpers/utils';
import { Horse } from '@/store/modules/horse';
import { HORSE } from '@/router';
import { mdiHorseVariant } from '@mdi/js';

interface Computed {
  currentSpace: Space;
  horses: Horse[];
  mdiHorseVariant: string;
}

interface Methods {
  forward(spaceId: string, horseId: string): void;
  stableName(n: number): string;
}

interface Props {
  stable: Stable;
  loading: boolean;
}

export default Vue.extend<Record<string, never>, Methods, Computed, Props>({
  name: 'StableCard',
  components: { ModuleIconCard, AvatarImageKit },
  props: {
    stable: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    forward(spaceId, horseId) {
      this.$router.push({
        name: HORSE,
        params: { spaceId: spaceId, horseId: horseId }
      });
    },
    stableName(n) {
      if (this.stable) {
        return shortStringDots(this.stable.name, n);
      }
      return '';
    }
  },
  computed: {
    ...mapState('UserModule', ['currentSpace']),
    mdiHorseVariant() {
      return mdiHorseVariant;
    },
    horses() {
      const horses: Horse[] = [];
      for (const id of this.stable.horses) {
        const horse = this.$store.getters['HorseModule/getById'](id);
        if (horse) horses.push(horse);
      }
      return horses;
    }
  }
});
