import { ValidationGroups } from 'vue/types/vue';
import { Validation } from 'vuelidate';
import { TranslateResult } from 'vue-i18n';
import { i18n } from '@/i18n';
import Vue from 'vue';

export default Vue.extend({
  methods: {
    required(
      fieldValidation: ValidationGroups & Validation,
      i18nCode = 'errorMessages.requiredField'
    ): TranslateResult | null {
      if (!fieldValidation.$dirty) return null;
      if (!fieldValidation.required) return i18n.t(i18nCode);
      return null;
    }
  }
});
